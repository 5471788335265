<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <p class="font-weight-bold">{{ $t('payPricing.q1') }}</p>
        <p>{{ $t('payPricing.a1') }}</p>

        <p class="font-weight-bold">{{ $t('payPricing.q2') }}</p>
        <p>{{ $t('payPricing.a2') }}</p>

        <p class="font-weight-bold">{{ $t('payPricing.q3') }}</p>
        <p>{{ $t('payPricing.a3') }}</p>

        <p class="font-weight-bold">{{ $t('payPricing.q4') }}</p>
        <p>{{ $t('payPricing.a4') }}</p>

        <p class="font-weight-bold">{{ $t('payPricing.q5') }}</p>
        <p>{{ $t('payPricing.a5') }}</p>

        <p class="font-weight-bold">{{ $t('payPricing.q6') }}</p>
        <p>{{ $t('payPricing.a6') }}</p>

        <p class="font-weight-bold">{{ $t('payPricing.q7') }}</p>
        <p>{{ $t('payPricing.a7') }}</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="4"></v-col>
<!--          <v-col cols="4" class="img-box">pretty pic of hardcover </v-col>-->
<!--          <v-col cols="4" class="img-box">pretty pic of softcover </v-col>-->
          <table style="width: 100%" class="px-0 py-0 pb-0">
            <tr>
              <td style="border: none; height: 300px;"></td>
              <td>pretty pic of hardcover </td>
              <td>pretty pic of softcover</td>
            </tr>
            <tr>
              <td>Base Price</td>
              <td>$42.50</td>
              <td>$27.50</td>
            </tr>
            <tr>
              <td>Additional Pages</td>
              <td>$1.25</td>
              <td>$.75</td>
            </tr>
            <tr>
              <td>Size</td>
              <td>10.5"x7"</td>
              <td>9"x6"</td>
            </tr>
            <tr>
              <td>Cover Finish</td>
              <td>Matte Finish</td>
              <td>Velvet Heavyweight</td>
            </tr>
            <tr>
              <td>Min/Max Pages</td>
              <td>30/200</td>
              <td>30/200</td>
            </tr>
            <tr>
              <td>Cover Design</td>
              <td>Customized Photo/Title</td>
              <td>Customized Photo/Title</td>
            </tr>
          </table>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PayPricing',
    data() {
      return {}
    }
  }
</script>

<style scoped>
/*table, th, td {*/
td {
  border: 1px solid black;
  font-size: 1.25em;
}

td {
  padding: 4px;
}

table {
  border-collapse: collapse;
}

.img-box {
  height: 300px;
  border: 1px solid #000;
}
</style>
